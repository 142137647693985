export const testiData = [
    {
        id: 1,
        name: 'Grishma M.',
        desc: 'Seif is a Great Freelancer. has an amazing Working Morale.'
    },
    {
        id: 2,
        name: 'Rahul D.',
        desc: 'Extremely professional. Keeps his commitments. Would love to work with him again.'
    },
    {
        id: 3,
        name: 'Bjoernoest',
        desc: 'blazing fast, also for the few edits I needed. very happy.'
    },
    {
        id: 4,
        name: 'Sam G.',
        desc: 'Did the job well. Good Freelancer. Very Patient.'
    },
];