import { IoLocationOutline, AiOutlineMobile, AiOutlineMail } from 'react-icons/all';


export const contact_data = [
    {
        id: 1,
        icon: <IoLocationOutline />,
        title: 'Address',
        details: 'Shobra Misr, Cairo, Egypt'
    },
    {
        id: 2,
        icon: <AiOutlineMobile />,
        title: 'Phone',
        details: '+201067001315'
    },
    {
        id: 3,
        icon: <AiOutlineMail />,
        title: 'Email',
        details: 'info@seifahmed.com'
    },
];