import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Style
import classes from './Header.module.css';

const Header = (props) => {
  return (
    <header className={`${classes.header} sec_style`}>
      <div className={classes.header_content}>
        <Container>
          <Row>
            <Col md={10}>
              <h1>{props.pageName}</h1>
            </Col>
            <Col md={2}>
              <p>
                <Link to='/home'>Home </Link>/ {props.pageName}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default Header;
