import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Typed from 'react-typed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// Style
import classes from './MainHeader.module.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
// Images
import myPhoto from '../../../util/images/seif.jpg';

const MainHeader = () => {
  return (
    <header className={classes.header}>
      <div className={classes.header_content}>
        <Container>
          <Row>
            <Col xl={6}>
              <div className={classes.image_banner}>
                <LazyLoadImage src={myPhoto} alt='my_image' effect='blur' />
                <div className={classes.circle_bg1}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </Col>

            <Col xl={{ span: 6, order: 'first' }}>
              <div className={classes.banner_content}>
                <h2>
                  Hello, I'm <br />
                  Seif Ahmed
                </h2>
                <h4>
                  I'm a Freelance&nbsp;
                  <Typed
                    strings={['Web Developer', 'Web Consultant', 'UX Designer']}
                    typeSpeed={70}
                    backSpeed={30}
                    loop
                  />
                </h4>
                <p>
                  Professional web developer with major experience in different
                  web technologies, great experience with web hosting services.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default MainHeader;
