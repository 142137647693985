import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

// Style
import classes from './Resume.module.css';

// Data
import { resumeData } from '../../data/resume';

const Resume = () => {
  return (
    <section className={`${classes.resume} sec_style`}>
      <Container>
        <h2 className='sec_heading'>My Resume</h2>
        <Row>
          {resumeData.map((item) => {
            return (
              <Col md={6} key={item.id}>
                <div className={classes.res_card}>
                  <h4>{item.position}</h4>
                  <p>
                    <span>{item.duration}</span>&nbsp;{item.company}
                  </p>
                  <hr />
                  <p>{item.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default Resume;
