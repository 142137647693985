import React, { useState, useEffect } from 'react';
// Bootstrap
import { Navbar, Container, Nav } from 'react-bootstrap';
// React Router
import { NavLink, useLocation } from 'react-router-dom';
//React Icons
import { BsMoon, BsSun, FaBars } from 'react-icons/all';
// Style
import './Navbar.css';

const getStorageTheme = () => {
  let theme = 'light-theme';
  if (localStorage.getItem('theme')) {
    theme = localStorage.getItem('theme');
  }
  return theme;
};

const Navigation = () => {
  const [navColor, setNavColor] = useState(false);
  const [theme, setTheme] = useState(getStorageTheme());

  const changeNavColor = () => {
    if (window.scrollY >= 40) {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
  };
  window.addEventListener('scroll', changeNavColor);

  const toggleTheme = () => {
    if (theme === 'light-theme') {
      setTheme('dark-theme');
    } else {
      setTheme('light-theme');
    }
  };

  useEffect(() => {
    document.documentElement.className = theme;
    localStorage.setItem('theme', theme);
  }, [theme]);

  const location = useLocation();
  const locFinder =
    location.pathname !== '/' && !navColor ? 'navlink_out' : 'navlink_in';

  return (
    <Navbar
      className={`${navColor ? 'nav_scroll' : ''}`}
      collapseOnSelect
      expand='lg'
      variant='dark'
      fixed='top'
    >
      <Container>
        <Navbar.Brand href='/home' className={`${locFinder}`}>
          SA<span>.</span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          className={locFinder}
        >
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='ms-auto'>
            <NavLink to='/home' className={`nav-link ${locFinder}`}>
              Home
            </NavLink>
            <NavLink to='/about' className={`nav-link ${locFinder}`}>
              About
            </NavLink>
            <NavLink to='/portfolio' className={`nav-link ${locFinder}`}>
              Portfolio
            </NavLink>
            <NavLink to='/contact' className={`nav-link ${locFinder}`}>
              Contact
            </NavLink>
            <button className={`mode_toggler ${locFinder}`} onClick={toggleTheme}>
              {theme === 'light-theme' ? <BsMoon /> : <BsSun />}
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
