import React from 'react';
// Components
import MainHeader from '../components/Headers/MainHeader/MainHeader';
import About from '../components/About/About';
import Portfolio from '../components/Portfolio/Portfolio';
import Resume from '../components/Resume/Resume';
import Testimonials from '../components/Testimonials/Testimonials';
import Contact from '../components/Contact/Contact';
import MainFooter from '../components/Footer/MainFooter/MainFooter';

const Home = () => {
  return (
    <>
      <MainHeader/>
      <About/>
      <Resume/>
      <Portfolio/>
      <Testimonials/> 
      <Contact/>
      <MainFooter/>
    </>
  );
};

export default Home;
