// Core
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Pages
import Home from './pages/Home';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import NotFound from './pages/NotFound';
// Components
import Layout from './components/layout/Layout';

function App() {
  return (
    <Layout>

        <Switch>
          <Route path='/' exact>
            <Home />
          </Route>

          <Route path='/home'>
            <Redirect to='/' />
          </Route>

          <Route path='/about'>
            <About />
          </Route>

          <Route path='/portfolio'>
            <Portfolio />
          </Route>

          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>
    </Layout>
  );
}

export default App;
