import React from 'react';
import Navigation from '../Navbar/Navbar';

const Layout = (props) => {
  return (
    <>
      <Navigation />
      {props.children}
    </>
  );
};

export default Layout;
