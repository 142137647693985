import React from 'react';
import { Col, Container, Row, ProgressBar } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// Style
import classes from './About.module.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
// Images
import infoImage from '../../util/images/seif_info.jpg';
// Data
import { basic_info, skills, about_p } from '../../data/about';

const About = () => {
  return (
    <section className={`sec_style ${classes.about}`}>
      <Container>
        <Row>
          <Col xs={12}>
            <div className={classes.aboutme}>
              <h2 className='sec_heading'>about me</h2>
              {about_p.map((item) => {
                return <p key={item.id}>{item.content}</p>;
              })}
            </div>
          </Col>

          <Col lg={4}>
            <div className={classes.info_image}>
              <LazyLoadImage src={infoImage} alt='my_photo' effect='blur' />
            </div>
          </Col>

          <Col lg={4}>
            <div className={classes.basic_info}>
              <h5 className='pr_heading'>Basic Info</h5>
              <ul>
                {basic_info.map((item) => {
                  return (
                    <li key={item.id}>
                      <span>{item.head}</span>
                      <p>{item.value}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>

          <Col lg={4}>
            <div className={classes.skills}>
              <h5 className='pr_heading'>Skills</h5>
              <ul>
                {skills.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <li>
                        <span>{item.tech}</span>
                        <p>{item.progress}%</p>
                      </li>
                      <ProgressBar now={item.progress} />
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default About;
