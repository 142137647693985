import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Style
import classes from './Portfolio.module.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
// Data
import { portfolioData } from '../../data/portfolio';

const Portfolio = () => {
  const location = useLocation();
  const locFinder = location.pathname === '/';
  return (
    <section className={`${classes.portfolio} sec_style`}>
      <Container>
        <h2 className='sec_heading'>My Portfolio</h2>
        <Row>
          {portfolioData.map((item, index) => {
            if (locFinder) {
              if (index < 6) {
                return (
                  <Col md={4} key={item.id}>
                    <div className={classes.content}>
                      <a href={item.link} target='_blank' rel='noreferrer'>
                        <div className={classes.content_overlay}></div>
                        <LazyLoadImage
                          className={classes.content_image}
                          src={item.imageSrc}
                          alt={item.title}
                          effect="blur"
                        />
                        <div
                          className={`${classes.content_details} fadeIn-top`}
                        >
                          <h3 className={classes.content_title}>
                            {item.title}
                          </h3>
                          <p className={classes.content_text}>{item.desc}</p>
                          {item.skills.map((skill, index) => {
                            return <span key={index}>{skill}</span>;
                          })}
                        </div>
                      </a>
                    </div>
                  </Col>
                );
              }
            } else {
              return (
                <Col md={4} key={item.id}>
                  <div className={classes.content}>
                    <a href={item.link} target='_blank' rel='noreferrer'>
                      <div className={classes.content_overlay}></div>
                      <LazyLoadImage
                        className={classes.content_image}
                        src={item.imageSrc}
                        alt={item.title}
                        effect="blur"
                      />
                      <div className={`${classes.content_details} fadeIn-top`}>
                        <h3 className={classes.content_title}>{item.title}</h3>
                        <p className={classes.content_text}>{item.desc}</p>
                        {item.skills.map((skill, index) => {
                          return <span key={index}>{skill}</span>;
                        })}
                      </div>
                    </a>
                  </div>
                </Col>
              );
            }
            return <React.Fragment key={item.id}></React.Fragment>;
          })}
        </Row>
        {locFinder && (
          <Link to='/portfolio' className='btn-pr'>
            Full Portfolio
          </Link>
        )}
      </Container>
    </section>
  );
};

export default Portfolio;
