import {
  basic,
  definfts,
  esign,
  famindra,
  movie,
  muon,
  omicsdb,
  payda,
  quiz,
  estate,
} from '../util/images/portfolio';

export const portfolioData = [
  {
    id: 1,
    link: 'https://projects.seifahmed.com/basic/',
    imageSrc: basic,
    title: 'Basic',
    desc: 'Landing Page',
    skills: ['HTML/CSS', 'Bootstrap', 'jQuery'],
  },
  {
    id: 2,
    link: 'https://projects.seifahmed.com/famindra/',
    imageSrc: famindra,
    title: 'Famindra',
    desc: 'Landing Page',
    skills: ['HTML/CSS', 'Bootstrap', 'jQuery'],
  },
  {
    id: 3,
    link: 'https://projects.seifahmed.com/quiz-app/',
    imageSrc: quiz,
    title: 'Quiz',
    desc: 'Quiz App',
    skills: ['React.js', 'Application'],
  },
  {
    id: 4,
    link: 'https://projects.seifahmed.com/definfts/',
    imageSrc: definfts,
    title: 'Definfts',
    desc: 'Website',
    skills: ['HTML/CSS', 'Bootstrap', 'JS'],
  },
  {
    id: 5,
    link: 'https://projects.seifahmed.com/moviedb/',
    imageSrc: movie,
    title: 'MovieDB',
    desc: 'Movies Website',
    skills: ['React.js', 'Application'],
  },
  {
    id: 6,
    link: 'https://projects.seifahmed.com/omicsdb/',
    imageSrc: omicsdb,
    title: 'OmicsDB',
    desc: 'Medical Website',
    skills: ['HTML/CSS', 'Bootstrap', 'jQuery'],
  },
  {
    id: 7,
    link: 'https://projects.seifahmed.com/esign/',
    imageSrc: esign,
    title: 'eSign',
    desc: 'Website',
    skills: ['HTML/CSS', 'Bootstrap', 'jQuery'],
  },
  {
    id: 8,
    link: 'https://projects.seifahmed.com/muon/',
    imageSrc: muon,
    title: 'muon',
    desc: 'Hosting Website',
    skills: ['HTML/CSS', 'Bootstrap', 'jQuery'],
  },
  {
    id: 9,
    link: 'https://projects.seifahmed.com/estate/',
    imageSrc: estate,
    title: 'estate',
    desc: 'Estate Website',
    skills: ['HTML/CSS', 'Bootstrap', 'jQuery'],
  },
  {
    id: 10,
    link: 'https://projects.seifahmed.com/payda/',
    imageSrc: payda,
    title: 'pay da',
    desc: 'Paygate Website',
    skills: ['HTML/CSS', 'Bootstrap', 'jQuery'],
  },
];