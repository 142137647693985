export const basic_info = [
  {
    id: 1,
    head: 'Full Name:',
    value: 'Seif Eldeen Ahmed',
  },
  {
    id: 2,
    head: 'Date of birth:',
    value: '22th of January',
  },
  {
    id: 3,
    head: 'Phone no:',
    value: '+201067001315',
  },
  {
    id: 4,
    head: 'Email:',
    value: 'info@seifahmed.com',
  },
  {
    id: 5,
    head: 'Location:',
    value: 'Cairo, Egypt',
  },
  {
    id: 6,
    head: 'Website:',
    value: 'www.seifahmed.com',
  },
  {
    id: 7,
    head: 'Experience',
    value: new Date().getFullYear() - 2018 + ' Years',
  },
  {
    id: 8,
    head: 'Freelance',
    value: 'Available',
  },
];

export const skills = [
  {
    id: 1,
    tech: 'Front End Technologies',
    progress: 85,
  },
  {
    id: 2,
    tech: 'Back End Technologies',
    progress: 95,
  },
  {
    id: 3,
    tech: 'UI/UX Design',
    progress: 75,
  },
  {
    id: 4,
    tech: 'Web Hosting Services',
    progress: 95,
  },
  {
    id: 5,
    tech: 'Bussiness Strategy',
    progress: 65,
  },
];

export const about_p = [
  {
    id: 1,
    content:
      'Starting out at the age of 14 I never stopped learning new programming skills and languages. Early I started creating websites for friends and just for fun as well. Besides web development I also explored Python and other non-web-only languages.',
  },
  {
      id: 2,
      content: 'This passion has since lasted and lead to my decision of working as a freelance web developer and consultant. The success and fun I have in this job is immense and really keeps that passion burningly alive.',
  },
  {
      id:3,
      content: 'Starting web development on the backend (PHP with Laravel, NodeJS, Python) I also became more and more of a frontend developer using modern frameworks like React, Angular or VueJS in a lot of projects. I love both worlds nowadays!'
  }
];