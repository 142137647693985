import React from 'react';
import NotFound from '../components/NotFound/NotFound';

const NotFoundPage = () => {
  return (
    <>
      <NotFound />
    </>
  );
};

export default NotFoundPage;
