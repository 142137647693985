import React from 'react';
import { Container } from 'react-bootstrap';
import { FaFacebookF, AiFillGithub, FaLinkedinIn } from 'react-icons/all';

// Style
import classes from './Footer.module.css';

const Footer = () => {
    return (
      <footer className={`${classes.footer} sec_style`}>
        <div className={classes.footer_content}>
          <Container>
            <h2>
              SA<span>.</span>
            </h2>
            <a href='https://fb.com/imseifahmed' target='_blank' rel="noreferrer">
              <FaFacebookF />
            </a>
            <a href='https://github.com/seifahmed7' target='_blank' rel="noreferrer">
              <AiFillGithub />
            </a>
            <a href='https://www.linkedin.com/in/imseifahmed/' target='_blank' rel="noreferrer">
              <FaLinkedinIn />
            </a>
            <hr />
            <p>
              &copy; {new Date().getFullYear()} All Rights Reserved By{' '}
              <span>Seif Ahmed</span>
            </p>
          </Container>
        </div>
      </footer>
    );
  };

  export default Footer;