import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Style
import classes from './Contact.module.css';
// Data
import { contact_data } from '../../data/contact';

const Contact = () => {
  return (
    <section className={`${classes.contact} sec_style`}>
      <Container>
        <h2 className='sec_heading'>Contact Me</h2>
        <Row>
          {contact_data.map((item) => {
            return (
              <Col md={4} key={item.id}>
                <div className={classes.contact_method}>
                  <div className={classes.icon}>{item.icon}</div>
                  <h5>{item.title}</h5>
                  <p>{item.details}</p>
                </div>
              </Col>
            );
          })}
        </Row>
        <Link to='/contact' className='btn-pr'>Contact Me</Link>
      </Container>
    </section>
  );
};

export default Contact;
