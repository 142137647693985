import React from 'react';

// Components
import Header from '../components/Headers/Header/Header';
import About from '../components/About/About';
import Footer from '../components/Footer/Footer/Footer';

const AboutPage = () => {
  return (
    <>
      <Header pageName='About Page' />
      <About />
      <Footer />
    </>
  );
};

export default AboutPage;