import React from 'react';

// Style
import classes from './NotFound.module.css';

const NotFound = () => {
    return (
        <section className={`${classes.notfound} sec_style`}>
            <h1>404 Page Not Found.</h1>
        </section>
    )
};

export default NotFound;