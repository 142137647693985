import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import { FaQuoteRight } from 'react-icons/fa';

// Style
import classes from './Testimonials.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SlickStyle.css';

// Data
import { testiData } from '../../data/testimonials';

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <section className={`${classes.testimonials} sec_style`}>
      <Container>
        <h2 className='sec_heading'>Clients Testimonials</h2>
        <p>
          This is what my clients have said throughout my time working with
          them,
          <br /> some of these reviews from freelancing sites.
        </p>
        <div className={classes.sliderContainer}>
          <Slider {...settings}>
            {testiData.map((item) => {
              return (
                <div className={classes.review} key={item.id}>
                  <FaQuoteRight className={classes.quote} />
                  <p>{item.desc}</p>
                  <h5>{item.name}</h5>
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </section>
  );
};

export default Testimonials;
