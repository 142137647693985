import React from 'react';

// Components
import Header from '../components/Headers/Header/Header';
import Portfolio from '../components/Portfolio/Portfolio';
import Footer from '../components/Footer/Footer/Footer';

const PortfolioPage = () => {
  return (
    <>
      <Header pageName='Portfolio Page' />
      <Portfolio/>
      <Footer />
    </>
  );
};

export default PortfolioPage;