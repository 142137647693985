export const resumeData = [
  {
    id: 1,
    position: 'Flashchat Partner',
    duration: '2014-2016',
    company: '123 Flashchat Inc.',
    desc: 'I worked for two years as a partner of Flashchat and have a really huge knowledge of how chat systems work.',
  },
  {
    id: 2,
    position: 'Startup manager',
    duration: '2015-2017',
    company: 'First Hosting Inc.',
    desc: 'I have been working for two years on my startup project and I have a great background in the world of hosting services.',
  },
  {
    id: 3,
    position: 'UI/UX Designer',
    duration: '2017-2018',
    company: 'Freelance',
    desc: 'I worked for about a year as an interface and user experience designer as a freelancer.',
  },
  {
    id: 4,
    position: 'Backend Developer',
    duration: '2020-2021',
    company: 'MC Inc.',
    desc: 'I worked for a year as a back-end developer with many technologies, and my role was to maintain and create powerful softwares.',
  },
];